import React from 'react'
import styled from 'styled-components'

import Layout from '../components/layout/layout'
import { DeepBackground, Button } from '../components/general/general'

const Background = styled(DeepBackground)`
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Heading = styled.h1`
    font-size: 3rem;
    margin-top: -5rem;
`;

const NotFound = () => {
    return (
        <Layout>
            <Background>
                <Heading>Page Not Found</Heading>
                <Button to="/">Head to Homepage</Button>
                
            </Background>
        </Layout>
    )
}

export default NotFound